import React from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Box,
  ThemeProvider,
  createTheme,
  CssBaseline,
} from '@mui/material';
import { Email as EmailIcon } from '@mui/icons-material';
import './App.css';
import GoogleAd from './GoogleAd';

import logo from './logo512.png';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
});

function App() {
  const blocks = [
    { title: 'Situation Idioms', description: 'Chinese Idioms in real-life political scenarios', image: 'images/situation-idioms.png?v=1', link: 'https://situation.profoundwords.us/' },
    // { title: 'Russell M. Nelson', description: 'Inspiring Stories', image: 'images/prophet17.jpg', link: 'https://prophet17.profoundwords.us/' },
    { title: 'Political Analysis Phrases', description: 'US Political Analysis Phrases', image: 'images/political-analysis.png', link: 'https://pap.profoundwords.us/' },
    { title: 'Ads Zone', description: 'Ads Testing Zone', image: '', link: 'https://ads.profoundwords.us/' },
    { title: 'Mint Mobile', description: 'Referral Link', image: 'images/mint-mobile.jpg', link: 'http://fbuy.me/uy2-s' },
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box className="app-container">
        <AppBar position="static">
          <Toolbar>
            <Box display="flex" alignItems="center" width="100%">
              <img src={logo} alt="Logo" style={{ height: 80, marginRight: 16 }} />
              <Typography variant="h5" component="div" className="app-title">
                <i>crumbs of wisdom</i>
              </Typography>
            </Box>
          </Toolbar>
        </AppBar>
        <Container maxWidth="lg" className="main-content">
          <Typography variant="h6" component="h1" gutterBottom className="intro-heading">
            <i>Bridging the Gap Between Thought & Expression</i>
          </Typography>

          <Typography variant="subtitle1" gutterBottom className="intro-subtitle">
            <u></u>
          </Typography>
          <br />
          <Grid container spacing={2}>
            {blocks.map((block, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box
                  onClick={() => {
                    if (block.link) {
                      window.open(block.link, '_blank', 'noopener,noreferrer');
                    }
                  }}
                  className={`card-container ${block.link ? 'clickable' : ''}`}
                >
                  <Card className="card">
                    <CardMedia
                      component="img"
                      // height="240"
                      image={block.image || 'https://via.placeholder.com/140'}
                      alt={block.title}
                    />
                    <CardContent className="card-content">
                      <Typography gutterBottom variant="h6" component="h5" className="card-title">
                        {block.title}
                      </Typography>
                      <Typography className="card-description">
                        {block.description}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              </Grid>
            ))}
              <Grid item xs={12} sm={6} md={3} key={blocks.length}>
                <Box>
                  <Card className="card">
                    <GoogleAd />
                  </Card>                  
                </Box>                
              </Grid>
          </Grid>
        </Container>
        <Box component="footer" className="footer">
          <Container maxWidth="lg">
            <Typography variant="body2" color="text.secondary" align="center">
              Copyright © 2024 Profound Words - All Rights Reserved.
            </Typography>
            <Typography variant="body2" color="text.secondary" align="center" className="contact-email">
              <Button
                startIcon={<EmailIcon />}
                href="mailto:rebecca25.sun@gmail.com"
                color="inherit"
                size="small"
              >
                Contact Email
              </Button>
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default App;